.card-wrapper {
  border-radius: 8px;
  background-color: var(--App-Background);
}

.card-tab {
  background-color: var(--App-Dark-Gray);
  padding: 8px;
  border-radius: 100px;
  transition: background-color 0.5s ease-in-out;
}

.card-tab:not(.card-tab-selected):hover {
  background-color: var(--Genesis-Dark-Blue);
}

.card-tab-selected {
  background-color: var(--Genesis-Blue-200);
}
