.timeline-tooltip {
  position: fixed;
  background-color: #2a2a2a;
  color: white;
  padding: 8px 12px;
  border-radius: 6px;
  font-size: 14px;
  z-index: 10000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  pointer-events: none;
  min-width: 200px;
  max-width: 300px;
}

.timeline-tooltip-content {
  width: 100%;
  word-wrap: break-word;
}

.timeline-tooltip-arrow {
  position: absolute;
  width: 8px;
  height: 8px;
  background: inherit;
  visibility: hidden;
}

.timeline-tooltip-arrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  background: inherit;
  visibility: visible;
  content: '';
  transform: rotate(45deg);
}

[data-popper-placement^='top'] .timeline-tooltip-arrow {
  bottom: -4px;
}

[data-popper-placement^='bottom'] .timeline-tooltip-arrow {
  top: -4px;
} 