.model-overlay {
  position: fixed;
  right: 0;
  bottom: 0;
  box-shadow: 4px 4px 10px 0 rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 100%;
  z-index: 200;
  background-color: rgba(0, 0, 0, 0.6);
}

.modal {
  position: fixed;
  right: 0;
  bottom: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2.5rem;
  flex-direction: column;
  align-items: center;
  gap: 1.25rem;
  border-radius: 0.5rem;
  background: var(--App-Dark-Gray);
  z-index: 201;
  max-height: 80vh;
  width: fit-content;

  overflow: auto;
}

.modal-close {
  background: none;
  cursor: pointer;
  position: absolute;
  left: calc(100% - 40px);
  color: var(--Genesis-White);
}

/* Small devices (phones) */
@media (max-width: 767px) {
  .modal {
    width: 592px;
    max-height: 447px;
  }
}

/* Medium devices (tablets) */
@media (min-width: 768px) {
  .modal {
    width: 592px;
    max-height: 447px;
  }
}

/* Large devices (desktops, laptops) */
@media (min-width: 992px) {
  .modal {
    width: 292px;
  }
}

/* Extra large devices (large desktops, high-resolution screens) */
@media (min-width: 1200px) {
  .modal {
    width: 592px;
    max-height: 447px;
  }
}

@media (min-width: 1400px) {
  .modal {
    width: 592px;
    max-height: 447px;
  }
}

.modal-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.modal-footer {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 1.2rem;
  bottom: 0;
  padding: 1.5rem 0;
  width: 100%;
}

.modal-open {
  overflow: hidden;
}
