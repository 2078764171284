.timeline-grid-container {
    display: flex;
    width: 100%;
  }
  
  .timeline-row-headers {
    width: 300px;
    flex-shrink: 0;
    border-right: 1px solid rgba(255, 255, 255, 0.1);
  }
  
  .timeline-header-spacer {
    height: 60px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  
  .timeline-row-header {
    height: 40px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    overflow: hidden;
  }
  
  .timeline-row-header-content {
    height: 100%;
  }
  
  .timeline-row-header-project {
    background: transparent;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  
  .timeline-row-header-task {
    background: transparent;
    padding-left: 24px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  }
  
  .timeline-task-title {
    font-size: 13px;
    line-height: 40px;
    margin: 0;
    padding: 0 32px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .timeline-grid {
    flex-grow: 1;
    overflow-x: auto;
    overflow-y: hidden;
    position: relative;
  }

  .timeline-month-column {
    position: absolute;
    top: 60px;
    height: calc(100% - 40px);
    background: transparent;
    z-index: 0;
  }
  
  .timeline-month-column:nth-child(even) {
    background: var(--App-Background);
  }
  
  .timeline-headers {
    position: relative;
    background-color: var(--background-color);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    z-index: 2;
    height: 60px;
  }
  
  /* Default styles for month view */
  .timeline-month-headers {
    position: relative;
    height: 60px;
  }
  
  /* Styles for day view */
  .timeline-headers.day-view {
    height: 60px;
  }
  
  .timeline-headers.day-view .timeline-month-headers {
    height: 20px;
  }
  
  .timeline-month-header {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    border-left: 1px solid rgba(255, 255, 255, 0.1);
    color: var(--text-color, white);
    font-size: 14px;
  }
  
  .timeline-grid-line {
    position: absolute;
    top: 0;
    width: 1px;
    background: rgba(255, 255, 255, 0.02);
    pointer-events: none;
    z-index: 1;
  }
  
  .timeline-grid-line-month {
    background: rgba(255, 255, 255, 0.1);
  }
  
  .timeline-month-separator {
    display: none;
  }
  
  .timeline-rows {
    position: relative;
    min-width: 100%;
    z-index: 1;
    overflow: visible;
  }
  
  .timeline-row {
    height: 40px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    position: relative;
    min-width: 100%;
  }
  
  .timeline-row-project {
    background: transparent;
    width: 100%;
  }
  
  .timeline-row-task {
    background: transparent;
    width: 100%;
  }
  
  .timeline-project-header {
    display: flex;
    align-items: center;
    width: 100%;
  }
  
  .timeline-project-header > div {
    flex: 1;
    padding: 8px;
  }
  
  .timeline-project-header > div:hover {
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 4px;
  }
  
  .timeline-collapse-icon {
    display: flex;
    align-items: center;
    padding: 8px;
    cursor: pointer;
    margin-right: 4px;
  }
  
  .timeline-collapse-icon:hover {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
  }
  
  .timeline-row-header-project {
    background: transparent;
  }
  
  .timeline-row-header-task {
    background: transparent;
    padding-left: 24px;
  }
  
  .timeline-row {
    transition: height 0.2s ease-out;
  }
  
  .timeline-project-header h3 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .timeline-project-header p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .timeline-task-bubble {
    position: absolute;
    top: 8px;
    height: 24px;
    background-color: rgba(68, 115, 218, 0.4);
    border: 1px solid #4473da;
    border-radius: 6px;
    border-left: 4px solid #4473da;
    transition: background-color 0.2s ease;
    /* cursor: move; */
    user-select: none;
    z-index: 2;
  }
  
  .timeline-task-bubble:hover {
    background-color: rgba(68, 115, 218, 0.5);
  }
  
  .timeline-task-bubble.opacity-50 {
    opacity: 0.5;
    border-style: dashed;
  }
  
  .timeline-row {
    height: 40px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    position: relative;
  }
  
  .timeline-row-project {
    background: transparent;
  }
  
  .timeline-row-task {
    background: transparent;
  }
  
  .timeline-row.drop-target {
    background-color: rgba(68, 115, 218, 0.1);
  }

  .timeline-task-title {
    cursor: pointer;
  }

  .timeline-task-title:hover {
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 4px;
  }

  .timeline-project-header h4 {
    font-size: 14px;
    margin: 0;
    padding: 0;
  }

  .timeline-day-headers {
    position: relative;
    height: 20px;
    background-color: var(--background-color);
  }
  
  .timeline-day-header {
    font-size: 10px;
    color: var(--Genesis-Gray-300);
    text-align: center;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

  .timeline-day-header-month-start {
    border-left: 1px solid rgba(255, 255, 255, 0.1);
  }

  /* New week headers styles */
  .timeline-week-headers {
    position: relative;
    height: 20px;
    background-color: var(--background-color);
  }

  .timeline-week-header {
    position: absolute;
    font-size: 11px;
    color: var(--Genesis-Gray-300);
    text-align: center;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid rgba(255, 255, 255, 0.05);
  }

  .timeline-week-header-month-start {
    border-left: 1px solid rgba(255, 255, 255, 0.1);
  }

  /* Update month headers for both views */
  .timeline-month-headers {
    position: relative;
    height: 60px; /* Default for months view */
  }

  /* Specific styles for day view */
  .timeline-headers.day-view .timeline-month-headers {
    height: 20px;
  }

  /* New styles for year headers in months view */
  .timeline-year-headers {
    position: relative;
    height: 30px;
    display: none; /* Hidden by default for day view */
  }

  .timeline-headers:not(.day-view) .timeline-year-headers {
    display: block; /* Show only in months view */
  }

  .timeline-headers:not(.day-view) .timeline-month-headers {
    height: 30px; /* Reduce month header height in months view */
  }

  .timeline-year-header {
    position: absolute;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--text-color, white);
    font-size: 16px;
    border-left: 1px solid rgba(255, 255, 255, 0.1);
  }

  /* Adjust month header styling for months view */
  .timeline-headers:not(.day-view) .timeline-month-header {
    font-size: 14px;
  }
