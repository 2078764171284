@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");

/*<link rel="stylesheet" href="//cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/leaflet.min.css"/>*/
/*<link rel="stylesheet" href="//cdnjs.cloudflare.com/ajax/libs/leaflet.draw/1.0.4/leaflet.draw.css"/>*/

.sr-only {
  display: none;
}

:root {
  /*colors*/
  --App-Background: #251f34;
  --App-Background-Hover: #2a2838;
  --App-Blue: #459fff;
  --App-Dark-Gray: #1f1a2b;
  --App-Darkest-Gray: #515154;
  --App-Green: #26de70;
  --App-Light-Gray: #f5f5f7;
  --App-Lightest-Gray: #fafafa;
  --App-Medium-Gray: #dcdcdc;
  --App-Red: #f86a61;
  --Black: #110f24;
  --Black-Muted: #110f2466;
  --Genesis-Black: #121417;
  --Genesis-Blue: #92d7e7;
  --Genesis-Blue-200: #654df6;
  --Genesis-Dark-Blue: #1f8299;
  --Genesis-Gray: #2b3138;
  --Genesis-Gray-200: #e6e6ea;
  --Genesis-Gray-300: #9e9cae;
  --Genesis-Gray-400: #6f6c84;
  --Genesis-Gray-500: #464453;
  --Genesis-Light-Blue: #f1faff;
  --Genesis-White: #ffffff;
  --Green: #85f482;
  --Genesis-Green-300: #1affd8;
  --Genesis-Green-400: #00e5be;
  --toastify-icon-color-success: #ffffff !important;
  --primary: #3e98c7;
  --primary-rgb: 62, 152, 199;
  --background-secondary: #1a1a1a;
  --background-tertiary: #2a2a2a;
  --background-hover: #333333;
  --text-primary: #ffffff;
  --text-secondary: #9ca3af;
  --border-color: #374151;
  --border-hover: #4b5563;
  --scrollbar-thumb: #4b5563;
  --scrollbar-track: #1f2937;

  /*fonts*/
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 14px;

  /*breakpoints*/
  --small: 767px;
  --medium: 768px;
  --large: 992px;
  --extra-large: 1200px;
  --extra-extra-large: 1400px;
}

body {
  font-family: "IBM Plex Sans", sans-serif;
  line-height: 1.875rem;
  font-size: 1.25rem;
  background-color: var(--App-Background);
}

h1 {
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 3.75rem;
}

h2 {
  font-feature-settings: "clig" off, "liga" off;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 3rem;
}

h3 {
  font-feature-settings: "clig" off, "liga" off;
  font-size: 1.6875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2.5625rem;
}

h4 {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  color: var(--Genesis-Gray-200);
}
.h4 {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  color: var(--Genesis-Gray-200);
}

.boldXL {
  font-feature-settings: "clig" off, "liga" off;
  font-size: 47px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.875rem; /* 150% */
}

.boldL {
  font-feature-settings: "clig" off, "liga" off;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.875rem; /* 150% */
}

.boldM {
  font-feature-settings: "clig" off, "liga" off;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5rem; /* 150% */
}

.boldS {
  font-feature-settings: "clig" off, "liga" off;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.25rem; /* 142.857% */
}

.semiboldXL {
  font-feature-settings: "clig" off, "liga" off;
  font-size: 47px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.875rem; /* 150% */
}

.semiboldL {
  font-feature-settings: "clig" off, "liga" off;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.875rem; /* 150% */
}

.semiboldM {
  font-feature-settings: "clig" off, "liga" off;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem; /* 150% */
}

.semiboldS {
  font-feature-settings: "clig" off, "liga" off;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.25rem; /* 142.857% */
}

.mediumXL {
  font-feature-settings: "clig" off, "liga" off;
  font-size: 47px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.875rem; /* 150% */
}

.mediumL {
  font-feature-settings: "clig" off, "liga" off;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.875rem; /* 150% */
}

.mediumM {
  font-feature-settings: "clig" off, "liga" off;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem; /* 150% */
}

.mediumS {
  font-feature-settings: "clig" off, "liga" off;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem; /* 142.857% */
}

.regularXXL {
  font-weight: 400;
  font-size: 20px;
  line-height: 1.875rem; /* 150% */
  font-style: normal;
}

.regularXL {
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.875rem; /* 150% */
  font-style: normal;
}

.regularL {
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.875rem; /* 150% */
  font-style: normal;
}

.regularM {
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 12px; /* 150% */
}

.regularS {
  font-feature-settings: "clig" off, "liga" off;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.regularXS {
  font-feature-settings: "clig" off, "liga" off;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

.App {
  text-align: center;
  font-family: "IBM Plex Sans", sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.white {
  color: var(--Genesis-White);
}

.red {
  color: var(--App-Red);
}

.primary-button {
  background-color: var(--Genesis-Green-400);
  color: var(--Genesis-White);
  border: none;
  border-radius: 8px;
  padding: 1.25rem 2.5rem;
  font-size: 1.25rem;
  font-weight: 700;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

input[type="username"],
input[type="password"],
input[type="email"],
input[type="text"] {
  display: flex;
  position: relative;
  align-items: center;
  align-self: stretch;

  border-radius: 8px;
  border: 1px solid var(--Genesis-Gray-300);

  background: transparent;
  color: var(--Genesis-Gray-300, #fff);
  height: 40px;

  /* Regular L */
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.875rem;
  padding: 7px 16px;
  /* width: 360px; */
}

input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 16px;
  height: 16px;
  border: 2px solid var(--Genesis-Green-400);
  border-radius: 0.25rem;
  background-color: #fff;
  outline: none;
  cursor: pointer;
  font-family: "IBM Plex Sans", sans-serif;
  margin-right: 7px;
}

/* Styling the checked state of the checkbox */
input[type="checkbox"]:checked {
  background-color: var(--Genesis-Green-400);
  border-color: var(--Genesis-Black);
  width: 16px;
  height: 16px;
}

/* Styling the checkmark inside the checkbox */
input[type="checkbox"]::before {
  content: "\2713";
  font-size: 10px;
  color: var(--Genesis-Black);
  display: block;
  text-align: center;
  /* line-height: 1.5rem; */
  visibility: hidden;
}

input[type="checkbox"]:checked::before {
  visibility: visible;
}

input:focus,
textarea:focus {
  outline: none;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  color: transparent;
}

input[type="date"]::before {
  content: "\f073"; /* This is a Font Awesome calendar icon. Replace it with your own icon if needed. */
  color: red; /* Change the color as needed */
  font-family: FontAwesome;
  position: absolute;
  right: 10px;
}

input[type="otp"] {
  display: flex;
  flex-direction: row;
  padding: 16px;
  border-radius: 8px;
  max-width: 55px;
  border: 1px solid var(--Genesis-Gray-300);
  background-color: transparent;
  color: var(--Genesis-White);
}

input[type="otp"]:focus {
  display: flex;
  flex-direction: row;
  padding: 16px;
  border-radius: 8px;
  max-width: 55px;
  border: 1px solid var(--Genesis-Green-400);
  background-color: transparent;
  color: var(--Genesis-White);
}

input:focus:not([type="checkbox"]),
textarea:focus {
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  border: 1px solid var(--Genesis-Green-400);
  background-color: transparent;
  color: var(--Genesis-White);
}

.field {
  display: flex;
  flex-direction: column;
}

label {
  color: var(--Genesis-Dark-Blue, #1f8299);

  /* Regular L */
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem;
}

label.question-label {
  color: var(--Genesis-Gray-300);
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5rem;
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.gray-200 {
  color: var(--Genesis-Gray-200);
}

.gray-300 {
  color: var(--Genesis-Gray-300);
}

.gray-400 {
  color: var(--Genesis-Gray-400);
}

.gray-500 {
  color: var(--Genesis-Gray-500);
}

.gray-bg-500 {
  background-color: var(--Genesis-Gray-500);
}

.gray-bg-300 {
  background-color: var(--Genesis-Gray-300);
}

.green-300 {
  color: var(--Genesis-Green-300);
}

.green-400 {
  color: var(--Genesis-Green-400);
}

.bg-green-400 {
  background-color: var(--Genesis-Green-400);
}

.border-green-400 {
  border: 1px solid var(--Genesis-Green-400);
}

.margin-24 {
  margin: 24px 0;
}

hr.solid {
  border-top: 0.4px solid var(--Genesis-Gray-500);
  border-bottom: none;
  border-right: none;
  border-left: none;
}

.align-center {
  display: flex;
  align-items: center;
}

.justify-center {
  display: flex;
  justify-content: center;
}

a {
  text-decoration: none;
}

.text-align-center {
  text-align: center;
}

.cursor-pointer {
  cursor: pointer;
}

.w-100 {
  width: 100%;
}
.w-90 {
  width: 90%;
}
.w-80 {
  width: 80%;
}
.padding-h-8 {
  padding-left: 8px;
  padding-right: 8px;
}

.margin-h-16 {
  margin-left: 16px;
}

.flex-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.justify-space-between {
  justify-content: space-between;
}

.w-10 {
  width: 10%;
}

.w-15 {
  width: 15%;
}

.w-20 {
  width: 20%;
}

.w-25 {
  width: 25%;
}
.w-28 {
  width: 28%;
}

.w-30 {
  width: 30%;
}

.w-35 {
  width: 35%;
}

.w-40 {
  width: 40%;
}

.w-45 {
  width: 45%;
}

.w-48 {
  width: 48%;
}

.w-50 {
  width: 50%;
}

.w-60 {
  width: 60%;
}
.w-65 {
  width: 65%;
}

.w-70 {
  width: 70%;
}

.w-80 {
  width: 80%;
}
.m-t-6 {
  margin-top: 6px;
}
.m-t-8 {
  margin-top: 8px;
}
.m-t-12 {
  margin-top: 12px;
}

.m-t-16 {
  margin-top: 16px;
}

.m-t-24 {
  margin-top: 24px;
}

.m-l-8 {
  margin-left: 8px;
}
.m-4 {
  margin: 4px;
}

.m-8 {
  margin: 8px;
}
.p-4 {
  padding: 4px;
}

.p-6 {
  padding: 6px;
}
.p-8 {
  padding: 8px;
}
.p-10 {
  padding: 10px;
}
.p-12 {
  padding: 12px;
}

.p-14 {
  padding: 14px;
}

.p-16 {
  padding: 16px;
}
.p-18 {
  padding: 18px;
}
.p-20 {
  padding: 20px;
}
.p-22 {
  padding: 22px;
}
.p-24 {
  padding: 24px;
}

.p-t-12 {
  padding-top: 12px;
}

.border-b-1 {
  border-bottom: 1px solid rgba(158, 156, 174, 0.12);
}

.card-second-wrapper {
  background-color: var(--App-Dark-Gray) !important;
}

.card-app-background {
  background-color: var(--App-Background);
}

.border-none {
  border: none !important;
}

.bg-transparent {
  background-color: transparent;
}

.quick-add-task-container {
  border-radius: 8px;
  border: 1px solid rgba(158, 156, 174, 0.3);
  background-color: transparent;
}

.border-top-task-add-container {
  border-top: 1px solid rgba(158, 156, 174, 0.3);
}

label.p-FieldLabel {
  color: var(--Genesis-White);
}

.animate {
  transition: all 0.5s ease-in-out;
}

.max-h-full {
  max-height: 100%;
}

.max-h-0 {
  max-height: 0;
}

.opacity-100 {
  opacity: 1;
}

.opacity-0 {
  opacity: 0;
}

.text-capitalize {
  text-transform: capitalize;
}

.react-datepicker__input-container input {
  display: flex;
  position: relative;
  align-items: center;
  align-self: stretch;

  border-radius: 8px;
  border: 1px solid var(--Genesis-Gray-300);

  background: transparent;
  color: var(--Genesis-Gray-300, #fff);
  height: 40px;

  /* Regular L */
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.875rem;
  padding: 7px 16px;
  /* width: 360px; */
}

.p-absolute {
  position: absolute;
}

.r-0 {
  right: 0;
}

.p-relative {
  position: relative;
}

.cursor-disabled {
  cursor: not-allowed;
}

.m-t-48 {
  margin-top: 48px;
}
.m-t-32 {
  margin-top: 32px;
}
.m-t-64 {
  margin-top: 64px;
}

.b-r-8 {
  border-radius: 8px;
}

.b-r-t-8 {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.black {
  color: var(--Black);
}

.create-label {
  color: var(--Genesis-Gray-300);
}

.create-project-input {
  display: flex;
  position: relative;
  align-items: center;
  align-self: stretch;

  border-radius: 8px;

  background: transparent;
  color: var(--Genesis-Gray-300, #fff);
  height: 40px;

  /* Regular L */
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.875rem;
  padding: 0px 16px;
}

.input-icon-wrapper {
  position: relative;
  display: inline-block;
}

.content-container {
  transition: all 0.5s ease-in-out;
}

.react-datepicker__input-container input {
  height: 13px !important;
  background-color: var(--App-Background);
  border: 1px solid rgba(158, 156, 174, 0.3);
}

.overview-platform-selector {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 16px;
  border-radius: 8px;
  border: 1px solid var(--Genesis-Green-400);
  background-color: var(--App-Background);
  color: var(--Genesis-Gray-300);
  height: 48px;
  /* width: 248px; */
}
.label[data-shrink="false"]
  + .MuiInputBase-formControl
  .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  border: none !important;
  height: 20px;
}

.trash-background {
  background-color: rgba(239, 72, 112, 0.1);
  width: 32px;
  height: 32px;
  border-radius: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-input {
  transition: width 0.5s ease-in-out, left 0.5s ease-in-out;
}

.react-select {
  border: 1px solid red;
}

.ant-picker-focused {
  background-color: transparent !important;
  border: 1px solid var(--Genesis-Green-400) !important;
}

.ant-picker-focused input:focus {
  border: none !important;
  padding: 0;
}

.ant-picker-input input {
  color: white !important;
}

.ant-picker:hover {
  background-color: transparent !important;
  border: 1px solid var(--Genesis-Green-400) !important;
}

/* Style for TimePicker */
.ant-picker {
  background-color: var(--App-Background) !important;
  border: 1px solid rgba(158, 156, 174, 0.3) !important;
  padding: 7px 16px !important;
  height: 40px !important;
}

.ant-picker input::placeholder {
  color: var(--Genesis-Gray-300) !important;
  opacity: 1 !important;
}

.ant-picker input {
  color: var(--Genesis-Gray-300) !important;
}

.ant-picker-suffix,
.ant-picker-clear,
.ant-picker-separator {
  color: var(--Genesis-Gray-300) !important;
}

/* Style for the dropdown */
.custom-time-picker-dropdown.ant-picker-dropdown {
  background-color: #1a1a1a;
}

.custom-time-picker-dropdown
  .ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell
  .ant-picker-time-panel-cell-inner {
  color: #fff;
}

.custom-time-picker-dropdown .ant-picker-time-panel {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
