/* ProjectPreviewTabs.css */

/* Container */
.project-tabs-container {
  width: 100%;
  margin: 0 auto;
}

/* Tab Navigation */
.tabs-navigation {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid var(--border-color);
}

.tab-item {
  padding: 0.75rem 1.5rem;
  cursor: pointer;
  transition: all 0.2s ease;
  color: var(--text-secondary);
  position: relative;
}

.tab-item:hover {
  color: var(--text-primary);
}

.tab-item.active {
  color: var(--text-primary);
  background: linear-gradient(
    to right,
    rgba(var(--primary-rgb), 0.1),
    transparent
  );
}

.tab-item.active::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--primary);
}

/* Project Card */
.project-card {
  background-color: var(--background-primary);
  border-radius: 8px;
  margin-bottom: 0.75rem;
  transition: all 0.3s ease;
  border: 1px solid var(--border-color);
}

.project-card:hover {
  border-color: var(--border-hover);
  transform: translateY(-2px);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

/* Card Header */
.project-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.project-header:hover {
  background-color: rgba(var(--background-rgb), 0.5);
}

.project-title {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.project-title h3 {
  color: var(--text-primary);
  font-size: 1.125rem;
  font-weight: 500;
}

.project-meta {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.task-count {
  color: var(--text-secondary);
  font-size: 0.875rem;
}

/* Progress Circle */
.progress-circle {
  width: 3rem;
  height: 3rem;
}

/* Accordion Content */
.accordion-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  opacity: 0;
  border-top: 1px solid var(--border-color);
  padding-top: 1rem;
}

.accordion-content.open {
  max-height: 300px;
  opacity: 1;
  transition: max-height 0.3s ease-in-out;
  overflow: hidden;
  position: relative;
}

.accordion-content.open.expanded-dropdown {
  max-height: 400px;
  overflow: visible;
}

/* Optional: Animate the chevron icon rotation */
.project-header .chevron-icon {
  transition: transform 0.3s ease;
}

.project-header .chevron-icon.open {
  transform: rotate(180deg);
}

/* Stats Grid */
.stats-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.5rem;
  padding: 0 0.75rem;
  margin-bottom: 1rem;
}

@media (min-width: 640px) {
  .stats-grid {
    gap: 0.75rem;
    padding: 0 1rem;
  }
}

@media (min-width: 1024px) {
  .stats-grid {
    gap: 1rem;
  }
}

.stat-card {
  background-color: var(--background-tertiary);
  border-radius: 8px;
  padding: 0.5rem;
  transition: all 0.2s ease;
  position: relative;
}

@media (min-width: 640px) {
  .stat-card {
    padding: 0.75rem;
  }
}

.stat-card:hover {
  transform: translateY(-2px);
  background-color: var(--background-hover);
}

.stat-label {
  color: var(--text-secondary);
  font-size: 0.75rem;
  line-height: 1rem;
}

@media (min-width: 640px) {
  .stat-label {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}

.stat-value {
  color: var(--text-primary);
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
}

@media (min-width: 640px) {
  .stat-value {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

@media (min-width: 1024px) {
  .stat-value {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}

/* Tasks List */
.tasks-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0 1rem;
  margin-bottom: 1rem;
}

.task-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--background-tertiary);
  border-radius: 6px;
  padding: 0.5rem;
  transition: all 0.2s ease;
}

.task-item:hover {
  background-color: var(--background-hover);
}

.task-title {
  color: var(--text-primary);
}

.task-title.completed {
  text-decoration: line-through;
  color: var(--text-secondary);
}

.task-date {
  font-size: 0.875rem;
  color: var(--text-secondary);
}

/* Action Buttons */
.action-buttons {
  display: flex;
  gap: 0.5rem;
  padding: 0 1rem;
}

.action-button {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}

/* Empty State */
.empty-state {
  background-color: var(--background-secondary);
  border-radius: 8px;
  border: 1px solid var(--border-color);
  padding: 2rem;
  text-align: center;
  color: var(--text-secondary);
}

/* Animations */
@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.accordion-content.open {
  animation: slideDown 0.3s ease forwards;
}

/* Custom ScrollBar */
.project-tabs-container {
  scrollbar-width: thin;
  scrollbar-color: var(--scrollbar-thumb) var(--scrollbar-track);
}

.project-tabs-container::-webkit-scrollbar {
  width: 6px;
}

.project-tabs-container::-webkit-scrollbar-track {
  background: var(--scrollbar-track);
}

.project-tabs-container::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb);
  border-radius: 3px;
}

/* Add these new styles */
.calendar-dark {
  width: 100%;
  background: transparent !important;
  border: none !important;
  color: white !important;
}

.calendar-dark button {
  color: white !important;
}

.calendar-dark .react-calendar__tile {
  padding: 1em 0.5em;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  margin: 2px;
  height: auto !important;
  min-height: 80px;
}

.calendar-dark .react-calendar__tile:enabled:hover,
.calendar-dark .react-calendar__tile:enabled:focus {
  background: rgba(255, 255, 255, 0.1);
}

.calendar-dark .react-calendar__tile--now {
  background: rgba(66, 153, 225, 0.3) !important;
}

.calendar-dark .react-calendar__tile--active {
  background: rgba(66, 153, 225, 0.5) !important;
}

.calendar-dark .react-calendar__navigation button {
  background: none;
}

.calendar-dark .react-calendar__navigation button:enabled:hover,
.calendar-dark .react-calendar__navigation button:enabled:focus {
  background: rgba(255, 255, 255, 0.1);
}

.calendar-dark .react-calendar__month-view__weekdays {
  color: rgba(255, 255, 255, 0.6);
}

/* Calendar events styling */
.calendar-events {
  margin-top: 4px;
}

.calendar-event {
  padding: 4px 8px;
  margin: 2px 0;
  border-radius: 4px;
  font-size: 0.875rem;
  color: white;
  cursor: pointer;
  transition: transform 0.2s;
}

.calendar-event:hover {
  transform: translateX(2px);
}

.event-title {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.75rem;
}

/* Time column styles */
.react-calendar__month-view__times {
  color: rgba(255, 255, 255, 0.6);
  font-size: 0.875rem;
}

/* Calendar Dark Theme */
.calendar-dark {
  background: transparent;
  color: white;
}

.calendar-dark .rbc-time-view {
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
}

.calendar-dark .rbc-time-header {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px 8px 0 0;
}

.calendar-dark .rbc-header {
  color: rgba(255, 255, 255, 0.8);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding: 8px;
}

.calendar-dark .rbc-time-content {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.calendar-dark .rbc-time-slot {
  color: rgba(255, 255, 255, 0.6);
}

.calendar-dark .rbc-event {
  padding: 4px 8px;
  border-radius: 4px;
  border: none;
  font-size: 0.875rem;
  border: 1px solid rgba(68, 115, 218, 1) !important;
  color: var(--Genesis-White) !important;
  background-color: rgba(68, 115, 218, 0.4) !important;
}

.rbc-day-slot .rbc-event,
.rbc-day-slot .rbc-background-event {
  border: 1px solid rgba(68, 115, 218, 1) !important;
  border-left: 8px solid rgba(68, 115, 218, 1) !important;
  background-color: rgba(68, 115, 218, 0.4) !important;
  border-radius: 8px !important;
}

.calendar-dark .rbc-today {
  background: rgba(66, 153, 225, 0.1);
}

.calendar-dark .rbc-time-gutter {
  color: rgba(255, 255, 255, 0.6);
}

.calendar-dark .rbc-timeslot-group {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  min-height: 60px;
}

.calendar-dark .rbc-current-time-indicator {
  background-color: #3182ce;
  height: 2px;
}

.calendar-dark .rbc-label {
  padding: 4px 8px;
  font-size: 0.875rem;
}

.calendar-dark .rbc-time-content > * + * > * {
  border-left: 1px solid rgba(255, 255, 255, 0.1);
}

/* Add these styles to hide navigation elements */
.calendar-dark .rbc-toolbar button {
  display: none;
}

.calendar-dark .rbc-toolbar {
  justify-content: center;
  padding: 12px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.calendar-dark .rbc-toolbar-label {
  flex: none;
  color: white;
  font-size: 1.1rem;
  font-weight: 500;
}

/* Update task container styles */
.tasks-scroll-container {
  height: 100%;
  overflow-y: auto;
  padding-right: 8px;
}

.tasks-fixed-section {
  flex: none; /* Don't allow this section to grow/shrink */
}

.tasks-scroll-section {
  flex: 1; /* Take remaining space */
  overflow-y: auto;
  margin-top: 8px;
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 255, 255, 0.2) transparent;
  padding-right: 8px;
}

.tasks-scroll-section::-webkit-scrollbar {
  width: 6px;
}

.tasks-scroll-section::-webkit-scrollbar-track {
  background: transparent;
}

.tasks-scroll-section::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 3px;
}

/* Add spacing between task cards */
.project-card {
  margin-bottom: 8px;
}

.tasks-scroll-section .project-card:last-child {
  margin-bottom: 0;
}

/* Add these to your existing CSS */
@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

/* Skeleton gradients */
.skeleton-gradient {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.03) 0%,
    rgba(255, 255, 255, 0.08) 50%,
    rgba(255, 255, 255, 0.03) 100%
  );
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

/* Custom scrollbar styling */
.tasks-scroll-container::-webkit-scrollbar {
  width: 6px;
}

.tasks-scroll-container::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 3px;
}

.tasks-scroll-container::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 3px;
}

.tasks-scroll-container::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.3);
}

/* Ensure dropdowns appear above other content */
.stat-card {
  position: relative;
}

/* Add smooth transitions */
.accordion-content {
  transition: all 0.3s ease-in-out;
}

/* Adjust dropdown positioning */
.stat-card .absolute {
  z-index: 50;
  max-height: 200px;
  overflow-y: auto;
}

.tasks-container {
  position: relative;
  padding-bottom: 80px; /* Space for fixed buttons */
}

.tasks-scroll-area {
  max-height: 250px; /* Adjusted height */
  overflow-y: auto;
  padding-right: 8px;
  margin-bottom: 8px;
}

.action-buttons-fixed {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px;
  background-color: var(--background-primary); /* Use theme variable */
  border-top: 1px solid var(--border-color);
  display: flex;
  gap: 8px;
  z-index: 10;
}

/* Add smooth shadow transition for better visual separation */
.action-buttons-fixed::before {
  content: "";
  position: absolute;
  top: -20px;
  left: 0;
  right: 0;
  height: 20px;
  background: linear-gradient(to top, var(--background-primary), transparent);
  pointer-events: none;
}

/* Ensure accordion content has proper spacing */
.accordion-content.open {
  max-height: none; /* Remove fixed height */
  padding-bottom: 0; /* Remove bottom padding */
  overflow: visible; /* Allow content to flow */
}

/* Add bottom margin to stats grid */
.stats-grid {
  margin-bottom: 1rem;
}
/* Scrollbar styling */
.tasks-scroll-area::-webkit-scrollbar {
  width: 6px;
}

.tasks-scroll-area::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 3px;
}

.tasks-scroll-area::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 3px;
}

.tasks-scroll-area::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.3);
}
