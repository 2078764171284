.input-container {
  position: relative;
  margin-top: 12px;
}

.input-container input {
  padding: 20px 40px;
  /* background-color: var(--App-Background); */
}

.input-container .icon {
  position: absolute;
  left: 20px; /* Adjust this value based on the size of your icon */
  top: 50%;
  transform: translateY(-50%);
}

.react-time-picker__inputGroup__input {
  color: #ffffff !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-time-picker__inputGroup__divider,
.react-time-picker__inputGroup__leadingZero {
  color: #ffffff !important;
}

.react-time-picker__inputGroup__divider {
  padding: 4px;
}
.react-time-picker__inputGroup input {
  /* background-color: var(--App-Background); */
  padding-left: 35px;
}

.react-time-picker__inputGroup input:last-child {
  /* background-color: var(--App-Background); */
  padding-left: 8px;
}

.react-time-picker__inputGroup {
  display: flex;
  flex-direction: row;

  /* width: 100px; */
  /* justify-content: center; */
}

.react-time-picker__wrapper {
  border: none !important;
  display: flex;
}

.react-time-picker__wrapper:focus {
  border: none !important;
  display: flex;
}

.input-container .time-icon {
  position: absolute;
  left: 20px; /* Adjust this value based on the size of your icon */
  top: 55%;
  transform: translateY(-50%);
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: block;
  width: 100%;
  height: 40px;
}

.react-datepicker__input-container input {
  box-sizing: border-box;
  height: 40px !important;
  padding: 0px 16px !important;
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 8px;
  border: 1px solid rgba(158, 156, 174, 0.3);
  background: var(--App-Background) !important;
  color: var(--Genesis-Gray-300);
  padding-left: 40px !important;
  font-size: 14px;
}

.react-datepicker__input-container input::placeholder {
  color: var(--Genesis-Gray-300);
  font-size: 14px;
}

.react-datepicker__input-container input:focus {
  border: 1px solid var(--Genesis-Green-400);
}

.react-datepicker-popper {
  z-index: 9999 !important;
}

.react-datepicker-wrapper {
  position: relative;
  z-index: 1;
}

.react-datepicker__input-container {
  position: relative;
  z-index: 1;
}