.tooltip-container {
  display: inline-block;
  position: relative;
  cursor: pointer;
  margin-left: 8px;
  vertical-align: middle;
}

.tooltip-box {
  visibility: hidden;
  width: 200px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -100px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-container:hover .tooltip-box {
  visibility: visible;
  opacity: 1;
} 