/* MyCalendar.css */

.rbc-calendar {
  background: transparent;
}

/* Customize the header */
.rbc-header {
  background: transparent;
  color: var(--Genesis-White);
  border: none;
}

/* Customize the selected date */
.rbc-selected {
  background: #007bff;
  color: white;
}

.rbc-day-bg {
  background-color: transparent;
  border: 1px solid rgba(158, 156, 174, 0.3);
}

.rbc-day-bg + .rbc-day-bg {
  background-color: transparent;
  border: 1px solid rgba(158, 156, 174, 0.3);
}
.rbc-month-row + .rbc-month-row {
  border-top: 0.6px solid --var(--Genesis-Gray-300) !important;
}

.rbc-header + .rbc-header {
  border: none;
}

.rbc-month-view {
  border: none;
}

.rbc-button-link {
  color: var(--Genesis-White);
}

.rbc-day-bg + .rbc-off-range-bg {
}

.rbc-event {
  border: 1px solid rgba(68, 115, 218, 1);
  color: var(--Genesis-White);
  background-color: rgba(68, 115, 218, 0.4);
}

.rbc-toolbar-label {
  color: var(--Genesis-White);
}

.rbc-time-header-content {
  border: none;
}

.rbc-time-header {
  border: none;
}

.rbc-time-view {
  border: none;
}

.rbc-label {
  color: var(--Genesis-White);
}

.rbc-time-content {
  border: none;
}

.rbc-events-container {
  border: none;
}

.rbc-time-content > * + * > * {
  border: none;
}

.rbc-day-slot .rbc-time-slot {
  border: none;
}

.rbc-day-slot .rbc-event,
.rbc-day-slot .rbc-background-event {
  border: 1px solid rgba(68, 115, 218, 1);
  border-left: 8px solid rgba(68, 115, 218, 1);
  background-color: rgba(68, 115, 218, 0.4);
  border-radius: 8px;
}

.rbc-timeslot-group {
  border-bottom: 1px solid rgba(70, 68, 83, 1);
  border-right: 1px solid rgba(70, 68, 83, 1);
}

.rbc-btn-group button {
  background-color: var(--App-Background);
  color: var(--Genesis-White);
  border: 1px solid rgba(158, 156, 174, 0.3);
  border-radius: 8px;
  padding: 8px;
  margin: 0 4px;
}

.rbc-btn-group button:hover {
  background-color: var(--Genesis-Blue-200);
  color: white;
}

.rbc-btn-group button.rbc-active {
  background-color: var(--App-Green);
}

.rbc-btn-group button.rbc-active:hover {
  background-color: var(--Genesis-Blue-200);
  color: white;
}

.rbc-time-content > .rbc-day-slot {
  background-color: transparent;
}

.hide-time-gutter .rbc-time-gutter {
  display: none;
}

.rbc-addons-dnd .rbc-row-content {
  pointer-events: none;
}

.rbc-addons-dnd .rbc-event {
  pointer-events: all;
  cursor: grab;
}

.rbc-addons-dnd .rbc-event:active {
  cursor: grabbing;
}

.rbc-addons-dnd .rbc-event-overlaps {
  box-shadow: -1px 4px 6px rgba(0, 0, 0, 0.2);
}

.rbc-event-drag-preview {
  opacity: 0.7;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  background-color: rgba(0, 0, 0, 0.1);
}

.rbc-day-slot .rbc-event {
  border: none;
}

.rbc-day-slot .rbc-background-event {
  opacity: 0.75;
}

.rbc-event-label {
  font-size: 80%;
}

.rbc-day-slot .rbc-event-content {
  background: inherit;
  width: 100%;
  height: 100%;
  padding: 2px 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Base styles for resizable events */
.rbc-addons-dnd .rbc-addons-dnd-resizable {
  display: flex;
}

/* Override for week view */
.rbc-time-view.rbc-week-view .rbc-addons-dnd .rbc-addons-dnd-resizable {
  display: block;
}

/* Specific styles for day view */
.rbc-time-view.rbc-day-view .rbc-addons-dnd .rbc-addons-dnd-resizable {
  display: flex;
  flex-direction: column;
}

/* Ensure proper event content layout in day view */
.rbc-time-view.rbc-day-view .rbc-event-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.time-gutter-header {
  font-size: 0.75rem;
  color: #666;
  padding: 4px;
  text-align: center;
}

.event-time {
  font-size: 0.75rem;
  opacity: 0.9;
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.event-time div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* All-day event styles */
.all-day-event-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 8px;
  margin: 2px 0;
  border-radius: 4px;
  color: white;
  font-size: 0.875rem;
  white-space: nowrap;
  overflow: hidden;
}

.all-day-event-title {
  font-weight: 500;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.all-day-event-location {
  font-size: 0.75rem;
  opacity: 0.9;
}

/* Adjust the all-day section in the calendar */
.rbc-allday-cell {
  max-height: auto !important;
  height: auto !important;
  padding: 4px !important;
}

.rbc-time-view .rbc-allday-cell {
  height: auto !important;
  max-height: none !important;
  min-height: 48px;
}

.rbc-time-view .rbc-row-content {
  z-index: 4;
}

.rbc-allday-cell .rbc-row-content {
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  width: 100%;
}

/* Hide all-day events from the main calendar grid */
.rbc-time-content .rbc-event.rbc-event-allday {
  display: none;
}

/* Ensure proper spacing in the header */
.rbc-time-header-content {
  min-height: auto;
}

.rbc-time-header-gutter {
  position: relative;
}

/* Status colors for all-day events */
.all-day-event-wrapper.status-pending {
  background-color: #f59e0b;
}
.all-day-event-wrapper.status-confirmed {
  background-color: #10b981;
}
.all-day-event-wrapper.status-cancelled {
  background-color: #ef4444;
}
.all-day-event-wrapper.status-inProgress {
  background-color: rgba(68, 115, 218, 0.4) !important;
}
.all-day-event-wrapper.status-completed {
  background-color: #059669;
}
.all-day-event-wrapper.status-default {
  background-color: rgba(68, 115, 218, 0.4) !important;
}

/* Add visual distinction for business hours (7 AM to 7 PM) */
.highlight-business-hours .rbc-time-slot {
  background-color: rgba(0, 0, 0, 0.03);
}

/* Highlight non-business hours */
.highlight-business-hours .rbc-time-slot:not(.business-hours) {
  background-color: rgba(0, 0, 0, 0.08);
}

/* Style for time slots between 7 AM and 7 PM */
.highlight-business-hours .rbc-time-slot[data-time-slot^="07"],
.highlight-business-hours .rbc-time-slot[data-time-slot^="08"],
.highlight-business-hours .rbc-time-slot[data-time-slot^="09"],
.highlight-business-hours .rbc-time-slot[data-time-slot^="10"],
.highlight-business-hours .rbc-time-slot[data-time-slot^="11"],
.highlight-business-hours .rbc-time-slot[data-time-slot^="12"],
.highlight-business-hours .rbc-time-slot[data-time-slot^="13"],
.highlight-business-hours .rbc-time-slot[data-time-slot^="14"],
.highlight-business-hours .rbc-time-slot[data-time-slot^="15"],
.highlight-business-hours .rbc-time-slot[data-time-slot^="16"],
.highlight-business-hours .rbc-time-slot[data-time-slot^="17"],
.highlight-business-hours .rbc-time-slot[data-time-slot^="18"],
.highlight-business-hours .rbc-time-slot[data-time-slot^="19"] {
  background-color: rgba(255, 255, 255, 0.4);
}

.rbc-addons-dnd .rbc-addons-dnd-row-body {
  width: 100%;
}

.show-more-popup {
  background-color: var(--background);
  color: var(--text);
}

.show-more-popup .popup-events::-webkit-scrollbar {
  width: 6px;
}

.show-more-popup .popup-events::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 3px;
}

.show-more-popup .popup-events::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}

.show-more-popup .popup-events::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.3);
}

.rbc-show-more {
  background-color: transparent !important;
  color: var(--Genesis-White) !important;
  cursor: pointer !important;
  padding: 2px 8px !important;
  font-size: 12px !important;
  text-decoration: none !important;
  display: block !important;
  text-align: center !important;
  border-radius: 4px !important;
}

.rbc-show-more:hover {
  background-color: rgba(68, 115, 218, 0.2) !important;
}

/* Popup Overlay */
.rbc-overlay {
  position: absolute !important;
  z-index: 9999 !important;
  background: var(--App-Background) !important;
  border: 1px solid var(--border) !important;
  border-radius: 8px !important;
  padding: 10px !important;
  min-width: 300px !important;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2) !important;
}

.rbc-overlay-header {
  padding: 8px !important;
  font-weight: bold !important;
  border-bottom: 1px solid var(--border) !important;
  margin-bottom: 8px !important;
  color: var(--Genesis-White) !important;
}

.rbc-overlay-event {
  padding: 8px !important;
  margin: 4px 0 !important;
  cursor: pointer !important;
  border-radius: 4px !important;
  background-color: rgba(68, 115, 218, 0.4) !important;
  color: var(--Genesis-White) !important;
  transition: background-color 0.2s ease !important;
}

.rbc-overlay-event:hover {
  background-color: rgba(68, 115, 218, 0.6) !important;
}

/* Make sure the calendar cells don't block interactions */
.rbc-row-segment {
  pointer-events: all !important;
}

.rbc-row-content {
  pointer-events: all !important;
}
