.priority-option {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px;
}

.priority-icon {
  width: 16px;
  height: 16px;
}

.priority-dropdown {
  min-width: 150px;
  background-color: var(--background-color);
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* Add media query for medium screens and up */
@media (min-width: 768px) {
  .priority-dropdown {
    position: absolute;
    top: -16px;
    left: -64px;
  }
}

/* Add styles for mobile */
@media (max-width: 767px) {
  .priority-dropdown {
    width: 100%;
    margin-top: 4px;
  }
}
