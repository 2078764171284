/* Add styles for the confirmation dialog */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.confirmation-dialog {
  position: absolute;
  background-color: #2b2d42;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  margin-top: 80px;
  width: 90%;
  max-width: 400px;
  color: white;
}

.confirmation-dialog p {
  margin-bottom: 20px;
  text-align: center;
}

.confirmation-dialog button {
  margin: 5px;
  min-width: 120px;
}

/* Media query for smaller screens */
@media screen and (max-width: 768px) {
  .confirmation-dialog {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    max-width: 100%;
    margin: 0;
    border-radius: 16px 16px 0 0;
    padding: 24px 16px;
  }

  .confirmation-dialog button {
    width: 100%;
    margin: 8px 0;
  }
}

/* Styles for buttons inside the confirmation dialog */
.confirmation-button {
  background-color: #00b894; /* Bright color for the "Yes, continue" button */
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
}

.cancel-button {
  background-color: #d63031; /* Red color for the cancel button */
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/* Add hover effects for better UI feedback */
.confirmation-button:hover {
  background-color: #009874; /* Slightly darker shade on hover */
}

.cancel-button:hover {
  background-color: #c0392b; /* Slightly darker shade on hover */
}
