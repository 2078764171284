@keyframes slideToTop {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  25% {
    transform: translateY(-50px);
    opacity: 1;
  }
  100% {
    transform: translateY(-20px);
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes celebrationPulse {
  0% {
    box-shadow: 0 0 0 0 rgba(74, 222, 128, 0.7);
  }
  70% {
    box-shadow: 0 0 0 15px rgba(74, 222, 128, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(74, 222, 128, 0);
  }
}

/* Confetti animations */
@keyframes confetti-fall {
  0% {
    transform: translateY(-10px) rotate(0deg);
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  100% {
    transform: translateY(100px) rotate(360deg);
    opacity: 0;
  }
}

@keyframes confetti-sway {
  0% {
    transform: translateX(0px);
  }
  33% {
    transform: translateX(-15px);
  }
  66% {
    transform: translateX(15px);
  }
  100% {
    transform: translateX(0px);
  }
}

@keyframes confetti-flutter {
  0% {
    transform: rotate(0deg);
  }
  33% {
    transform: rotate(-10deg);
  }
  66% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

/* Animation for confetti overlay */
@keyframes confettiAppear {
  0% {
    opacity: 0;
    transform: translateY(-20px) scale(0.9);
  }
  15% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
  85% {
    opacity: 1;
    transform: translateY(calc(90vh - 100%));
  }
  100% {
    opacity: 0;
    transform: translateY(90vh);
  }
}

/* New animation for the card to slide up from stack */
@keyframes slideUpFromStack {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  70% {
    transform: translateY(-10px);
    opacity: 1;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.cardStack {
  position: relative;
  height: 180px;
  margin: 2rem 0;
  perspective: 1000px;
}

.completedCardContainer {
  position: relative;
  margin-bottom: 16px;
  width: 100%;
  min-height: 90px;
}

.completedCard {
  position: relative;
  padding: 0.75rem;
  background: #262034;
  border-radius: 0.75rem;
  border: 1px solid #4ade80;
  box-shadow: 0px 4px 4px 0px #00000040;
  animation: slideToTop 0.5s cubic-bezier(0.4, 0, 0.2, 1) forwards,
    celebrationPulse 2s infinite;
  z-index: 20;
  opacity: 1;
  transition: opacity 0.5s ease-out;
}

.completedCard.fadeOut {
  opacity: 0;
  pointer-events: none;
}

.card {
  position: absolute;
  width: 100%;
  padding: 1rem;
  background: #262034;
  border-radius: 0.75rem;
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  backface-visibility: hidden;
  transform-origin: top center;
  border: 1px solid #2b2735;
  box-shadow: 0px 4px 4px 0px #00000040;
}

.card.current {
  z-index: 10;
  transform: translateY(0) scale(1);
}

.card.stacked {
  pointer-events: none;
}

/* Remove specific nth-child rules and use a more dynamic approach */
.card.stacked {
  --offset: 12px;
  --scale: 0.97;
  transform: translateY(calc(var(--stack-index) * var(--offset)))
    scale(calc(1 - (var(--stack-index) * 0.03)));
  opacity: calc(1 - (var(--stack-index) * 0.2));
  z-index: calc(10 - var(--stack-index));
}

/* Progress bar animation */
.progressBar {
  height: 4px;
  background: linear-gradient(90deg, #7c3aed 0%, #4ade80 100%);
  transition: width 0.3s ease-in-out;
  border-radius: 2px;
}

/* Status indicators */
.statusDot {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 0.5rem;
}

.statusDot.completed {
  background-color: #4ade80;
}

.statusDot.inProgress {
  background-color: #f4cc29;
}

.statusDot.pending {
  background-color: #6f6c84;
}

/* Top of modal completed section cards */
.topCompletedCardsContainer {
  position: fixed;
  right: 0;
  top: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1200; /* Higher than modal z-index */
  pointer-events: none;
  width: 400px; /* Match modal width */
}

.topCompletedCard {
  position: relative;
  margin-bottom: 1rem;
  background: #251f34;
  border-radius: 1rem;
  padding: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.fadeOut {
  opacity: 0;
  transform: translateY(-100%);
  transition: all 0.5s ease;
}

/* Add confetti pieces */
.topCompletedCard::before,
.topCompletedCard::after {
  content: "";
  position: absolute;
  z-index: -1;
  left: 50%;
  top: 50%;
  width: 8px;
  height: 16px;
  border-radius: 0;
  opacity: 0;
  animation: confetti-fall 1.5s ease-out forwards,
    confetti-sway 2s ease-in-out infinite;
}

.topCompletedCard::before {
  background-color: #7c3aed; /* Purple */
  animation-delay: 0.3s;
  left: 20%;
  transform-origin: center bottom;
}

.topCompletedCard::after {
  background-color: #f59e0b; /* Orange/yellow */
  animation-delay: 0.6s;
  left: 80%;
  transform-origin: center bottom;
}

/* Add more confetti particles using child pseudo elements */
.confettiContainer {
  position: absolute;
  top: -20px;
  left: -20px;
  right: -20px;
  bottom: -20px;
  overflow: visible;
  z-index: -1;
  pointer-events: none;
}

.confettiPiece {
  position: absolute;
  top: 20px;
  opacity: 0;
  transform-origin: center;
}

/* Square confetti */
.confettiPiece:nth-child(1),
.confettiPiece:nth-child(6) {
  width: 8px;
  height: 8px;
  background-color: #4ade80; /* Green */
  left: 10%;
  animation: confetti-fall 2.5s ease-out forwards,
    confetti-sway 3s ease-in-out infinite;
}

/* Circle confetti */
.confettiPiece:nth-child(2),
.confettiPiece:nth-child(7) {
  width: 10px;
  height: 10px;
  background-color: #f59e0b; /* Orange */
  border-radius: 50%;
  left: 30%;
  animation: confetti-fall 2.7s ease-out forwards,
    confetti-sway 2.5s ease-in-out infinite;
  animation-delay: 0.2s;
}

/* Rectangle confetti */
.confettiPiece:nth-child(3),
.confettiPiece:nth-child(8) {
  width: 6px;
  height: 14px;
  background-color: #7c3aed; /* Purple */
  left: 50%;
  animation: confetti-fall 2.3s ease-out forwards,
    confetti-sway 3.5s ease-in-out infinite,
    confetti-flutter 2s ease-in-out infinite;
  animation-delay: 0.4s;
}

/* Triangle confetti */
.confettiPiece:nth-child(4),
.confettiPiece:nth-child(9) {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 12px solid #ec4899; /* Pink */
  background-color: transparent;
  left: 70%;
  animation: confetti-fall 2.9s ease-out forwards,
    confetti-sway 3s ease-in-out infinite;
  animation-delay: 0.6s;
}

/* Star confetti */
.confettiPiece:nth-child(5),
.confettiPiece:nth-child(10) {
  width: 12px;
  height: 12px;
  background-color: #22d3ee; /* Cyan */
  left: 90%;
  clip-path: polygon(
    50% 0%,
    61% 35%,
    98% 35%,
    68% 57%,
    79% 91%,
    50% 70%,
    21% 91%,
    32% 57%,
    2% 35%,
    39% 35%
  );
  animation: confetti-fall 2.1s ease-out forwards,
    confetti-sway 2.7s ease-in-out infinite,
    confetti-flutter 1.5s ease-in-out infinite;
  animation-delay: 0.8s;
}

/* Second wave of confetti at different positions */
.confettiPiece:nth-child(6) {
  left: 20%;
  animation-delay: 0.3s;
}

.confettiPiece:nth-child(7) {
  left: 40%;
  animation-delay: 0.5s;
}

.confettiPiece:nth-child(8) {
  left: 60%;
  animation-delay: 0.7s;
}

.confettiPiece:nth-child(9) {
  left: 80%;
  animation-delay: 0.9s;
}

.confettiPiece:nth-child(10) {
  left: 95%;
  animation-delay: 1.1s;
}

.topCompletedCard.fadeOut {
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.completionBadge {
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  background: transparent;
  color: #4ade80;
  font-size: 12px;
  font-weight: bold;
  padding: 0;
  display: flex;
  align-items: center;
  gap: 4px;
}

.card.fadeOut {
  animation: slideUpFadeOut 0.8s ease-out forwards;
}

/* For backward compatibility, keep floating cards but make them optional */
.floatingCompletedCards {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(
    calc(50% + 220px),
    -50%
  ); /* Position to the right of modal */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 320px; /* Slightly smaller than the modal */
  z-index: 1100;
  gap: 12px;
}

.floatingCard {
  width: 100%;
  padding: 1rem;
  background: #262034;
  border-radius: 0.75rem;
  border: 2px solid #4ade80;
  box-shadow: 0 10px 25px -5px rgba(0, 0, 0, 0.5);
  animation: slideIn 0.5s cubic-bezier(0.4, 0, 0.2, 1) forwards,
    celebrationPulse 2s infinite;
  opacity: 1;
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
}

.floatingCard.fadeOut {
  opacity: 0;
  transform: translateX(30px);
}

@keyframes slideIn {
  0% {
    transform: translateX(50px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Confetti overlay image */
.confettiOverlay {
  position: absolute;
  top: -40px;
  left: -40px;
  width: calc(100% + 80px);
  height: calc(100% + 80px);
  z-index: -1;
  pointer-events: none;
  animation: confettiAppear 0.5s ease-out forwards;
  animation-delay: 0.2s;
  opacity: 0;
  transform-origin: center;
}

/* Modal-level confetti overlay */
.modalConfettiOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
  pointer-events: none;
  animation: confettiAnimation 4s ease-out forwards;
}

@keyframes confettiAnimation {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  90% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1.1);
  }
}

/* Add multiple confetti overlays for a more dynamic effect */
.modalConfettiOverlay:nth-child(1) {
  animation-delay: 0s;
}

.modalConfettiOverlay:nth-child(2) {
  animation-delay: 0.5s;
}

.modalConfettiOverlay:nth-child(3) {
  animation-delay: 1s;
}

.animate-fadeOut {
  animation: fadeOut 0.5s ease forwards;
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-100%);
  }
}
