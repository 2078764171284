.dropdown-container {
  position: relative;
}

.dropdown-trigger {
  position: relative;
  cursor: pointer;
  height: 38px;
  padding: 0 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #9e9caf1f;
  background: rgb(36, 31, 51);
  color: #fff;
  font-size: 14px;
  transition: all 0.2s ease-in-out;
}

.dropdown-trigger svg {
  color: #fff;
  width: 16px;
  height: 16px;
}

.dropdown-trigger:hover {
  background: rgba(255, 255, 255, 0.05);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.dropdown-list {
  position: absolute;
  width: 100%;
  top: calc(100%);
  left: 0;
  list-style: none;
  border: 1px solid #9e9caf1f;
  padding: 4px;
  background: rgb(36, 31, 51);
  border-radius: 4px;
  z-index: 1000;
  margin: 0;
  max-height: 200px;
  overflow-y: auto;
}

/* Add styles for scrollbar */
.dropdown-list::-webkit-scrollbar {
  width: 8px;
}

.dropdown-list::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.dropdown-list::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 4px;
}

.dropdown-option {
  padding: 8px 12px;
  cursor: pointer;
  color: #fff;
  border-radius: 4px;
  transition: background-color 0.2s ease;
}

.dropdown-option:hover {
  background: rgba(255, 255, 255, 0.1);
}

.selected {
  background: rgba(255, 255, 255, 0.1);
  font-weight: normal;
}

/* Animation for dropdown */
.dropdown-list {
  animation: dropdownFade 0.1s ease-in-out;
}

@keyframes dropdownFade {
  from {
    opacity: 0;
    transform: translateY(-4px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.dropdown-trigger.placeholder {
  color: #9e9caf; /* or any other gray color that matches your other input placeholders */
}

.dropdown-trigger.disabled {
  cursor: not-allowed;
  opacity: 0.6;
  background: rgba(36, 31, 51, 0.5);
}

.dropdown-trigger.disabled:hover {
  background: rgba(36, 31, 51, 0.5);
  box-shadow: none;
}
