.model-overlay {
  position: fixed;
  right: 0;
  bottom: 0;
  box-shadow: 4px 4px 10px 0 rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 100%;
  z-index: 200;
  background-color: rgba(0, 0, 0, 0.6);
}

.dialog {
  position: fixed;
  right: 0;
  bottom: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1.5rem;
  flex-direction: column;
  align-items: center;
  gap: 1.25rem;
  border-radius: 0.5rem;
  background: rgba(31, 26, 43, 1);
  border: 0.5px solid rgba(209, 208, 255, 0.5);
  z-index: 9999;
  max-height: 80vh;
  overflow: auto;
  width: fit-content;
}

/* Small devices (phones) */
@media (max-width: 767px) {
  .dialog {
    width: 50vw;
  }
}

/* Medium devices (tablets) */
@media (min-width: 768px) {
  .dialog {
    width: 50vw;
  }
}

/* Large devices (desktops, laptops) */
@media (min-width: 992px) {
  .dialog {
    width: 50vw;
  }
}

/* Extra large devices (large desktops, high-resolution screens) */
@media (min-width: 1200px) {
  .dialog {
    width: 50vw;
  }
}

@media (min-width: 1400px) {
  .dialog {
    width: 30vw;
  }
}

.dialog-header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.25rem;
  text-align: center;
  width: 100%;
}

.modal-footer {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 1.2rem;
  bottom: 0;
  padding: 1.5rem 0;
  width: 100%;
}
