.sidebar-container {
  position: relative;
}

.toggle-button {
  position: fixed;
  top: 2.25rem;
  left: 1rem;
  z-index: 101;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
}

.sidebar {
  position: fixed;
  left: 0;
  display: flex;
  flex-direction: column;
  background-color: rgba(31, 26, 43, 0.8);
  align-items: center;
  height: calc(100vh - 32px);
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  z-index: 100;
  overflow-x: hidden;
  margin: 16px 0 16px 16px;
  border-radius: 16px;
  transition: transform 0.3s ease-in-out;
}

.sidebar::-webkit-scrollbar {
  display: none;
}

.sidebar-icon {
  padding-top: 0.4rem;
  margin-left: 1.25rem;
  margin-right: 1.25rem;
  color: white;
}

.sidebar-item {
  min-height: 3.12rem;
  height: 3.12rem;
  display: flex;
  cursor: pointer;
  align-items: center;
  align-self: stretch;
  margin: 0.25rem 1.25rem;
  padding: 0.25rem 1.25rem;
  justify-items: center;
  gap: 0.3125rem;
  border-radius: 0.625rem;
  transition: background-color 0.3s ease;
  color: white;

  &:hover {
    background-color: var(--Genesis-Blue);
  }
}

hr {
  width: 70%;
  border: 0.0625rem solid var(--App-Medium-Gray);
  margin: 0.65rem;
}

.icon {
  position: fixed;
  top: 2.25rem;
  left: 0.25rem;

  z-index: 100;

  &:hover {
    color: var(--Rove-Dark-Blue);
    cursor: pointer;
  }
}

.open {
  transform: rotate(0deg);
  transition: transform 0.5s ease-in-out;
}

.close {
  transform: rotate(180deg);
  transition: transform 0.5s ease-in-out;
}

.sidebar-opened {
  transform: translateX(0);
  width: 250px;
}

.sidebar-closed {
  transform: translateX(-120%);
  width: 80px;
  /* visibility: hidden; */
}

@media (max-width: 768px) {
  .sidebar-opened {
    width: 80px;
  }

  .sidebar-closed {
    transform: translateX(-120%);
    width: 80px;
    visibility: hidden;
  }

  .toggle-button {
    top: 1.5rem;
    left: 0.5rem;
    padding: 0.25rem;
    transform: scale(0.8);
  }
}
