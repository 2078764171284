.services-linear-gradient-title {
  max-width: 500px;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background: linear-gradient(90deg, #5554a4 -17.69%, #73c9bd 117.35%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}
.services-linear-gradient-subtitle {
  color: var(--Sub-Text, #5e656b);
  font-feature-settings: "clig" off, "liga" off;
  font-size: 14px;
  font-style: normal;
  font-weight: 5 00;
  line-height: normal;
}

.service-choose-title {
  margin-top: 24px;
}
.service-choose-title-text {
  color: #141521;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 133.333% */
}
.service-card-outline {
  width: 105%;
  border: 1px solid black;
}
.service-component-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px;
}
.service-icon-container {
  border-radius: 50px;
  background: var(--Light-Violet, #f5f2ff);
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.next-button-container {
  margin-top: 24px;
}

.service-professional-image-container {
}

.professional-image {
  width: 48px;
  height: 48px;
  border-radius: 48px;
}

.back-icon-container {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  background-color: #f6f6f6;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 16px;
}

.search-container {
  margin: 24px 0;
}

.service-row-container {
  margin: 16px;
}
