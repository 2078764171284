.header {
  display: flex;
  height: 5rem;
  justify-content: space-between;
  align-items: center;
  gap: 1.25rem;
  flex-shrink: 0;
}

.header-title {
  font-feature-settings: "clig" off, "liga" off;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.125rem;
}

.user-view-container {
  display: flex;
  padding: 0.3125rem 1.25rem 0.3125rem 0.3125rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 6.25rem;
  background: var(--Rove-Light-Blue, #f1faff);
  cursor: pointer;
  position: relative;

  &:hover {
    background: var(--Rove-Blue, #1f8299);
  }
}

.user-view-container.active {
  z-index: 50;
}

.user-avatar {
  border-radius: 2.25rem;
  width: 2.25rem;
  align-self: center;
  height: 2.26rem;
}

.user-avatar-2 {
  width: 8rem;
  height: 8rem;
  border-radius: 8rem;
}

.user-view-pop-up {
  position: fixed;
  top: 70px;
  right: 30px;
  justify-content: center;
  align-items: center;

  display: flex;
  padding: clamp(1rem, 4vw, 2.5rem);
  flex-direction: column;
  gap: 1.25rem;

  border-radius: 0.5rem;
  background: var(--App-Background);
  box-shadow: 4px 4px 10px 0 rgba(0, 0, 0, 0.25);

  width: min(90vw, 400px);
  max-height: 80vh;
  overflow-y: auto;

  z-index: 50;
}

.user-info {
  text-align: center;
  /* margin-top: 1.35rem; */
  display: flex;
  flex-direction: column;
  width: 100%;
}

/* .user-info * {
  font-size: clamp(0.875rem, 2vw, 1rem);
  word-wrap: break-word;
} */

.divider {
  width: 100%;
  height: 0.0625rem;
  background: #dcdcdc;
}

.header-actions-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  gap: 17px;
}

@media (max-width: 480px) {
  .user-view-pop-up {
    right: 50%;
    transform: translateX(50%);
    top: 80px;
  }

  .user-avatar-2 {
    width: clamp(4rem, 20vw, 8rem);
    height: clamp(4rem, 20vw, 8rem);
  }
}

.calc-width {
  width: calc(100% - 240px); /* Adjust 240px to match your sidebar width */
}
