.labeled-option {
  display: flex;
  align-items: center;
  gap: 8px;
}

.option-icon {
  width: 16px;
  height: 16px;
}

.labeled-dropdown .dropdown-option {
  display: flex;
  align-items: center;
}

.labeled-dropdown .dropdown-trigger {
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.2s ease-in-out;
}

/* Hide only the chevron icon by default */
.labeled-dropdown .dropdown-trigger .chevron-icon {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

/* Show chevron and add hover effect */
.labeled-dropdown .dropdown-trigger:hover {
  background: rgba(255, 255, 255, 0.05);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.labeled-dropdown .dropdown-trigger:hover .chevron-icon {
  opacity: 1;
}



.labeled-dropdown .dropdown-trigger,
.labeled-dropdown .dropdown-list {
  width: 100%;
} 

/* Ensure consistent text size */
.labeled-dropdown .regularXS {
  font-size: 14px;
  line-height: 1.5;
} 