.Toastify__toast-container--bottom-center {
  /* width: 100% !important; */
  /* display: flex; */
  text-align: center;
}

.full-width-toast {
  width: 100vw !important;
  box-sizing: border-box;
}

.success-toast {
  background-color: rgba(26, 148, 127, 1) !important;
  color: white !important;
}
.error-toast {
  background-color: rgba(172, 70, 92) !important;
  color: white !important;
  font-weight: 700;
}

.Toastify__close-button--light {
  color: white !important;
  opacity: 1 !important;
}

.Toastify__close-button {
  align-self: center !important;
}

.Toastify__toast-body > div:last-child {
  font-weight: 700;
  font-family: "IBM Plex Sans", sans-serif;
}

.toast-icon-container {
  display: flex;
  align-items: center;
}

.toast-message {
  margin-left: 10px;
}

.toast-progress-bar {
  background: white !important;
}
