.h1 .title {
    color: var(--Rove-Blue, #92D7E7);
}

.container {
    display: flex;
}


.box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-direction: column;
    flex: 1;
}


.login-image {
    background: linear-gradient(180deg, #2E3038 0%, #000 100%);
}

.image {
    width: 70%;
}


.form-side {
    width: 70%;
}

.remember-me-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 1.3rem;
}


.left {
    text-align: start;
}

.right {
    text-align: end;
}


@media only screen and (max-width: 600px) {
    .box.login-image {
        display: none;
    }


    .form-side {
        width: 90%;
    }
}