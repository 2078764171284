/* Animation for confetti overlay */
@keyframes confettiAppear {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  15% {
    opacity: 1;
    transform: scale(1);
  }
  85% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* Modal-level confetti overlay */
.modalConfettiOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  pointer-events: none;
  animation: confettiAppear 4s ease-out forwards;
  opacity: 0;
  transform-origin: center;
  object-fit: cover;
  border-radius: 16px;
}

/* Add multiple confetti overlays for a more dynamic effect */
.modalConfettiOverlay:nth-child(1) {
  animation-delay: 0s;
}

.modalConfettiOverlay:nth-child(2) {
  animation-delay: 0.5s;
}

.modalConfettiOverlay:nth-child(3) {
  animation-delay: 1s;
}
