button {
  display: inline-flex;
  padding: 0px 2.5rem;
  margin: 0.2rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  flex-shrink: 0;
  border-radius: 0.25rem;
  box-shadow: none;
  border: none;
  cursor: pointer;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.875rem;

  font-family: "IBM Plex Sans", sans-serif;
}

button:disabled {
  border: 1px solid var(--Genesis-Blue);
  background: var(--Genesis-Blue);
  color: var(--Rove-White);
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.857rem;
  font-family: "IBM Plex Sans", sans-serif;

  &:hover {
    background: var(--Genesis-Blue);
  }

  cursor: not-allowed;
}

.filled-button {
  background-color: var(--Genesis-Green-400);
  transition: background-color 0.1s ease;

  &:hover {
    background-color: var(--Genesis-Blue) !important;
  }
}

.outlined-primary-button {
  border: 0.6px solid var(--Genesis-Green-400);
  background-color: transparent;
  color: var(--Genesis-Green-400);
}

.outlined-button {
  border-radius: 0.25rem;
  background: var(--App-Background);
  color: var(--Genesis-White);
  transition: background-color 0.1s ease, color 0.1s ease;

  &:hover {
    background-color: var(--Genesis-Blue) !important;
    color: black;
  }
}

.secondary-button {
  background-color: var(--Genesis-Blue-200);
  width: 100%;
  color: var(--Genesis-White);
  border: 0.6px solid var(--Genesis-Gray-300);
  border-radius: 8px;
}

.primary-outlined-button {
  border: 0.6px solid var(--Genesis-Green-400);
  background-color: transparent;
  color: white;
}

/* Update action buttons styling */
.action-buttons {
  display: flex;
  gap: 0.5rem;
  padding: 0 1rem;
}

.action-button {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}

/* Style for danger button */
.action-button[variety="danger-button"] {
  background-color: rgba(239, 68, 68, 0.1);
  color: rgb(239, 68, 68);
  border: 1px solid rgba(239, 68, 68, 0.2);
}

.action-button[variety="danger-button"]:hover {
  background-color: rgba(239, 68, 68, 0.2);
  border-color: rgba(239, 68, 68, 0.3);
}

.danger-button {
  background-color: rgba(239, 68, 68, 0.1);
  color: rgb(239, 68, 68);
  border: 1px solid rgba(239, 68, 68, 0.2);
}
