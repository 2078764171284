.h1 .title {
  color: var(--Rove-Blue, #92d7e7);
}

.container {
  display: flex;
  justify-content: flex-start;
}

.container .login-banner {
  width: 50%;
}

.container .login-banner .image {
  width: 100%;
}
.box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  flex: 1;
  background: linear-gradient(180deg, #2f2054 -20.7%, #110d1c 109.91%);
}

.login-image {
}

.image {
  height: 100%;
}

.form-side {
  max-width: 390px;
}

.remember-me-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.3rem;
}

.left {
  text-align: start;
}

.right {
  text-align: end;
}

.dotted-image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 211px;
  width: 379px;
  border: 2px dashed gray;
  border-radius: 5px;
  margin-top: 20px;
  position: relative;
}

.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (max-width: 600px) {
  .box.login-image {
    display: none;
  }

  .form-side {
    width: 60%;
  }

  .remember-me-container {
    width: 60%;
  }
}
