.level-up-modal {
  animation: fadeIn 0.3s ease-out;
}

.confetti-background {
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(1px);
}

.badge-container {
  position: relative;
  background-color: #1f1a2b;
  overflow: hidden;
}

.badge-container::before {
  content: "";
  position: absolute;
  inset: 0;
  background: radial-gradient(
    circle at center,
    rgba(124, 58, 237, 0.1) 0%,
    transparent 70%
  );
}

.badge-wrapper {
  position: relative;
  width: 180px;
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: pulse 2s infinite ease-in-out;
}

.level-badge-svg {
  filter: drop-shadow(0px 0px 15px rgba(124, 58, 237, 0.3));
}

.badge-inner {
  animation: glow 3s infinite alternate;
}

.bowtie-effect {
  display: flex;
  height: 20px;
  overflow: hidden;
}

.bowtie-effect div {
  position: relative;
  display: inline-block;
  transform-origin: center;
  transition: all 0.3s;
}

.bowtie-effect div:first-child {
  transform: translate(10px, 0) rotate(45deg);
}

.bowtie-effect div:last-child {
  transform: translate(-10px, 0) rotate(-45deg);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
    filter: drop-shadow(0 0 5px rgba(124, 58, 237, 0.5));
  }
  50% {
    transform: scale(1.03);
    filter: drop-shadow(0 0 15px rgba(124, 58, 237, 0.8));
  }
  100% {
    transform: scale(1);
    filter: drop-shadow(0 0 5px rgba(124, 58, 237, 0.5));
  }
}

@keyframes glow {
  0% {
    filter: brightness(0.9);
  }
  50% {
    filter: brightness(1.1);
  }
  100% {
    filter: brightness(0.9);
  }
}

/* Add subtle sparkle effect around the badge */
.badge-wrapper::before,
.badge-wrapper::after {
  content: "";
  position: absolute;
  width: 200%;
  height: 200%;
  border-radius: 50%;
  background: radial-gradient(
    circle,
    rgba(124, 58, 237, 0.1) 0%,
    rgba(124, 58, 237, 0) 70%
  );
  transform-origin: center;
  animation: sparkle 4s infinite ease-in-out;
  z-index: -1;
}

.badge-wrapper::after {
  animation-delay: 1s;
  width: 180%;
  height: 180%;
}

@keyframes sparkle {
  0% {
    transform: scale(0.8) rotate(0deg);
    opacity: 0.3;
  }
  50% {
    transform: scale(1.2) rotate(180deg);
    opacity: 0.7;
  }
  100% {
    transform: scale(0.8) rotate(360deg);
    opacity: 0.3;
  }
}
