.footer {
  display: flex;
  height: 3.125rem;
  padding: 0.625rem 0;
  justify-content: flex-end;
  align-items: flex-start;
  align-self: stretch;
  margin-top: 2.5rem;
}

.copyRight {
  color: var(--Rove-Gray, #2b3138);
  text-align: right;
  /* Bold L */
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.875rem; /* 150% */
  margin-right: 0.2rem;
  margin-left: 0.2rem;
}

.company {
  color: var(--Rove-Dark-Blue, #1f8299);

  /* Bold L */
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.875rem;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
}

.rights {
  color: var(--Rove-Gray, #2b3138);

  /* Regular L */
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.875rem;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
}
