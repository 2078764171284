.project-dropdown {
  background: var(--Genesis-Gray-800);
  border-radius: 8px;
  padding: 8px;
  min-width: 160px;
}

.dropdown-content {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.dropdown-item {
  padding: 8px 12px;
  cursor: pointer;
  color: white;
  border-radius: 4px;
}

.dropdown-item:hover {
  background: var(--Genesis-Gray-700);
}

.dropdown-item.selected {
  background: var(--Genesis-Gray-600);
}

.project-option {
  padding: 8px 12px;
  cursor: pointer;
  color: white;
  border-radius: 4px;
}

.project-option.selected {
  background: var(--Genesis-Gray-600);
}

.project-option:hover {
  background: var(--Genesis-Gray-700);
}
