/**
 * CSS animations for the Completion Badge component
 */

@keyframes popOut {
  0% {
    transform: translate(-50%, -50%) scale(0.5);
    opacity: 0;
  }
  60% {
    transform: translate(-50%, -50%) scale(1.1);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
}

@keyframes slideUp {
  0% {
    transform: translate(-50%, -50%);
    opacity: 1;
  }
  30% {
    transform: translate(-50%, -60%);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -130%);
    opacity: 1;
  }
}

@keyframes float {
  0% {
    transform: translate(-50%, -130%);
  }
  50% {
    transform: translate(-50%, -135%);
  }
  100% {
    transform: translate(-50%, -130%);
  }
}

.badge-hidden {
  opacity: 0;
  visibility: hidden;
  transform: translate(-50%, -50%) scale(0.5);
}

.badge-popping {
  animation: popOut 0.4s ease-out forwards;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 50;
}

.badge-sliding {
  animation: slideUp 0.6s ease-out forwards;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 50;
}

.badge-complete {
  animation: float 3s ease-in-out infinite;
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 50;
}

/* Stack effect shadow */
.badge-stack {
  position: relative;
}

.badge-stack::before,
.badge-stack::after {
  content: "";
  position: absolute;
  border-radius: inherit;
  z-index: -1;
  opacity: 0.6;
  transition: all 0.3s ease-out;
}

.badge-stack::before {
  bottom: -4px;
  left: 3px;
  right: 3px;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
}

.badge-stack::after {
  bottom: -8px;
  left: 6px;
  right: 6px;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
}

/* Confetti effect */
.badge-confetti {
  position: relative;
  overflow: hidden;
}

.confetti-piece {
  position: absolute;
  width: 8px;
  height: 8px;
  background: #ffb347;
  top: -20px;
  opacity: 0;
}

.confetti-piece:nth-child(1) {
  left: 10%;
  background: #f44336;
  animation: confettiDrop 2s ease-in-out infinite;
  animation-delay: 0.1s;
}

.confetti-piece:nth-child(2) {
  left: 30%;
  background: #4caf50;
  animation: confettiDrop 2.2s ease-in-out infinite;
  animation-delay: 0.3s;
}

.confetti-piece:nth-child(3) {
  left: 50%;
  background: #2196f3;
  animation: confettiDrop 1.8s ease-in-out infinite;
  animation-delay: 0.5s;
}

.confetti-piece:nth-child(4) {
  left: 70%;
  background: #ffc107;
  animation: confettiDrop 2.4s ease-in-out infinite;
  animation-delay: 0.2s;
}

.confetti-piece:nth-child(5) {
  left: 90%;
  background: #9c27b0;
  animation: confettiDrop 2s ease-in-out infinite;
  animation-delay: 0.4s;
}

@keyframes confettiDrop {
  0% {
    top: -20px;
    opacity: 0;
    transform: rotate(0deg);
  }
  10% {
    opacity: 1;
  }
  100% {
    top: 100%;
    opacity: 0;
    transform: rotate(360deg);
  }
}
